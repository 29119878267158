// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable sort-keys */
import { ThemeOptions } from '@material-ui/core/styles'

const theme: ThemeOptions = {
  typography: {
    h1: {
      fontFamily: "'Playfair Display', serif",
      fontSize: '4rem',
      fontWeight: 500,
    },
    h2: {
      fontFamily: "'Playfair Display', serif",
      fontSize: '3rem',
      fontWeight: 500,
    },
    h3: {
      fontFamily: "'Playfair Display', serif",
      fontSize: '2rem',
      fontWeight: 400,
    },
    h4: {
      fontFamily: "'Playfair Display', serif",
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 2,
    },
    h5: {
      fontFamily: "'Playfair Display', serif",
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1.0rem',
      lineHeight: 1.8,
      letterSpacing: '0.01071em',
    },
    button: {
      fontWeight: 400,
    },
    caption: {
      letterSpacing: '0.01071em',
    },
    subtitle1: {
      fontWeight: 400,
      lineHeight: '1rem',
    },
    overline: {
      fontSize: '0.8rem',
      lineHeight: 1,
      textTransform: 'none',
    },
  },
  palette: {
    type: 'light',
    common: {
      black: 'rgba(0, 0, 0, 1)',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    primary: {
      light: '#b6f470',
      main: '#83c13f',
      dark: '#519000',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    secondary: {
      light: '#43558c',
      main: '#102d5e',
      dark: '#000134',
      contrastText: 'rgb(255,255,255)',
    },
    error: {
      light: '#e57373',
      main: 'rgba(234, 144, 137, 1)',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: '#7c7c7c',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
}

export default theme
