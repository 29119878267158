import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles'
import React, { FC, ReactNode } from 'react'

import theme from '../styles/theme'

const wrapRootElement: FC<{ element: ReactNode }> = ({ element }) => (
  <ThemeProvider theme={responsiveFontSizes(createMuiTheme(theme))}>
    {element}
  </ThemeProvider>
)

export default wrapRootElement
